<template>
  <div class="home page">
    <div>
      <v-img height="30vh"
             src="https://images.unsplash.com/photo-1587620962725-abab7fe55159?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80"></v-img>

      <v-img
          style="border-radius: 50%; border-style: solid; border-color: white; border-width: 2px; position: absolute; top: 17vh; left: 10%"
          height="25vh" width="25vh" src="../assets/Ich2.png">
        <button style="width: 100%; height: 100%" @click="goAbout" @mouseover="imageOverlay = true"
                @mouseleave="imageOverlay = false">
          <div style="width: 100%; height: 100%; background-color: #00000066" v-show="imageOverlay">
            <h5 style="color: white; padding-top: 40%">
              About
            </h5>
          </div>
        </button>
      </v-img>
    </div>

    <div style="text-align: center; margin-top: 90px">
      <h2>
        {{ sideTextTitle }}
      </h2>
      <div style="width: 50vw; margin: auto; max-height: 50vh; overflow: hidden;" id="topText">
        <p style="font-size: large">
          {{ sideText }}
        </p>
      </div>
      <router-link to="about">
        Mehr lesen
      </router-link>
    </div>

    <b-card-group columns style="margin: 50px 30px 30px;">
      <b-card
          img-src="https://images.unsplash.com/photo-1605379399642-870262d3d051?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1081&q=80"
          overlay
          text-variant="white"
          style="overflow: auto"
          img-height="100%"
      >
        <img
            style="background-image: linear-gradient(0deg, #000000aa, #000000aa); width: 100%; height: 100%; position: absolute; left: 0; top: 0">
        <div style="position: absolute">
          <b-card-title>
            Neustes Projekt
          </b-card-title>
          <v-divider dark/>
          <b-card-text style="padding-right: 10px">
            Mein neustes Projekt finden Sie vor sich. Es ist diese Website. Ich arbeite nun seit der letzten
            Sommerferienwochen mit Vue an ihr und habe grossen Spass dabei. Am Anfang hatte ich keinen Plan, was ich
            machen sollte, aber
            inzwischen habe ich viele Ideen. Falls Sie Vorschläge haben, schreiben Sie mir doch gerne ein E-Mail.
          </b-card-text>
        </div>
        <router-link to="projects/test-project"
                     style="position: absolute; bottom: 5px; color: lightgray; pointer-events: none">>Projekt<
        </router-link>
      </b-card>


      <b-card
          img-src="https://cdn.ultiworld.com/wordpress/wp-content/uploads/2019/09/eucf-pools-1024x683.jpg"
          overlay
          style="overflow: auto"
          text-variant="white"
          img-height="100%"
      >
        <img
            style="background-image: linear-gradient(0deg, #77777735, #77777735); width: 100%; height: 100%; position: absolute; left: 0; top: 0">
        <div style="position: absolute">
          <b-card-title>
            Sport
          </b-card-title>
          <v-divider dark/>
          <b-card-text style="padding-right: 10px">
            Ich spiele seit 4 Jahren bei den Flying Angels Ultimate Frisbee und habe grossen Spass.
            Ultimate ist ein Teamsport, der mit zwei Teams von je sieben Spielenden (plus
            AuswechselspielerInnen) auf einem rechteckigen Rasenspielfeld gespielt wird. Punkte werden erzielt, indem
            die Scheibe in der gegnerischen Endzone gefangen wird. Das Team, das die vorgegebene Punktzahl (in der Regel
            15) zuerst erreicht, gewinnt. Als Spieler braucht man gute Kondition, Wurftechniken, Überblick über das
            Spielfeld und Teamplay. Das Wichtigste beim Ultimate Frisbee ist das Fairplay und deswegen gibt es bei
            Turnieren auch einen Fairplaypreis, der sogar wichtiger ist, als der erste Platz.
          </b-card-text>
        </div>
        <a href="https://flyingangels.ch/"
           style="position: absolute; bottom: 5px; color: white">>Flying Angels<
        </a>
      </b-card>


      <b-card title="Erfahrung" sub-title="Meine Erfahrungen mit Programmieren und Anderem." style="min-width: 300px">
        <v-list>
          <v-list-item v-for="experience in experiences">
            <h6>
              {{ experience.direction }}
            </h6>
            <v-spacer/>
            <b-progress style="width: 50%; margin-top: 10px; margin-right: 10px" :value="experience.experience"
                        :max="100" class="mb-3"/>
            <h6>
              {{ experience.experience }}
            </h6>
          </v-list-item>
        </v-list>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import cubeBackground from '../components/Cube-Background'
import gradient from "../components/gradient";
import router from "../router";

export default {
  name: 'Home',

  methods: {
    goAbout() {
      router.push('about')
    }
  },

  data: () => ({
    imageOverlay: false,
    sideText: 'Ich bin nicht nur am Computer sehr begabt, sondern auch im Handwerklichen. Ich experimentiere gerne und habe\n' +
        '            schon mit dem Arduino und Breadboards gearbeitet. Ich kann gut programmieren und interessiere mich für\n' +
        '            allerlei Zeug, das mit Elektrizität zu tun hat. Ich habe schon gelötet, meine eigenen Schaltkreise entworfen\n' +
        '            und die Platinen bestellt. Ich spiele gerne bei den Flying Angels Ultimate Frisbee und mit meinen\n' +
        '            Freunden am Computer Videogames.',
    sideTextTitle: 'Über mich',
    experiences: [
      {"direction": "Kotlin", "experience": 90},
      {"direction": "Java", "experience": 85},
      {"direction": "C#", "experience": 60},
      {"direction": "Python", "experience": 80},
      {"direction": "CSS", "experience": 65},
      {"direction": "Html", "experience": 85},
      {"direction": "JavaScript", "experience": 60},
      {"direction": "C", "experience": 12},
      {"direction": "Rust", "experience": 10},
      {"direction": "Unity", "experience": 60},
      {"direction": "KiCad", "experience": 55},
      {"direction": "Microsoft Programme", "experience": 70},
    ]
  }),

  components: {
    cubeBackground,
    gradient,
  }
}
</script>

<style>

#topText {
  mask-image: linear-gradient(0deg, transparent 10%, white);
}

</style>