<template>
  <div class="project page" style="width: 100%">
    <div class="container">
      <div class="overlay">
        <h1
            style="margin: 20px 20px 20px 1%; color: #333333"
        >
          Projekte
        </h1>

        <v-item-group active-class="primary">
          <v-container>
            <v-row>
              <v-col
                  v-for="(myProject, n) in myProjects"
                  :key="n"
                  cols="12"
                  md="0"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                      :ripple="false"
                      class="d-flex align-center"
                      @click="toggle"
                      :color="$vuetify.theme.currentTheme.primary"
                      dark
                      :img="myProject.image"
                      style="max-width: 1200px"
                  >
                    <div style="width: 100%">

                      <v-card-title v-if="!myProject.dark">
                        {{ myProject.title }}
                      </v-card-title>

                      <v-card-title v-if="myProject.dark" style="color: black">
                        {{ myProject.title }}
                      </v-card-title>

                      <v-card-subtitle v-if="!myProject.dark">
                        {{ myProject.subtitle }}
                      </v-card-subtitle>

                      <v-card-subtitle v-if="myProject.dark" style="color: black">
                        {{ myProject.subtitle }}
                      </v-card-subtitle>

                      <v-expand-transition>
                        <div v-show="active">
                          <v-divider v-if="!myProject.dark"/>
                          <v-divider v-if="myProject.dark" style="background-color: #888888"/>

                          <v-card-text>
                            <p v-if="!myProject.dark">
                              {{ myProject.description }}
                            </p>

                            <p v-if="myProject.dark" style="color: black">
                              {{ myProject.description }}
                            </p>

                            <a v-if="myProject.link !== ''" :href="myProject.link" style="margin-bottom: 10px; color: aliceblue">
                              Full Description
                            </a>
                          </v-card-text>

                        </div>
                      </v-expand-transition>
                    </div>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </div>
    </div>
  </div>
</template>

<script>

import Project from '../components/Project.js'
import CubeBackground from '../components/Cube-Background'
import gradient from "../components/gradient";

export default {
  name: "Projects",
  animSpeed: 0.5,

  data: () => ({
    myProjects: [
      new Project("Website", "Meine eigene Website!   ヾ(^▽^*)))", "Mein neustes Projekt finden Sie vor sich. Es ist diese Website. Ich arbeite nun seit der letzten Sommerferienwoche mit Vue an ihr und habe grossen Spass dabei. Am Anfang hatte ich keinen Plan, was ich machen sollte, aber inzwischen habe ich viele Ideen. Falls Sie Vorschläge haben, schreiben Sie mir doch gerne ein E-Mail.", "", "/lib/Website.png"),
      new Project("GPU Programming", "Der erste Versuch eine Grafikkarte zu programmieren.", "Ein Freund hat mich gefragt, ob ich ihm ein Programm zum Passwort-Cracken schreiben kann, das vorhersagt, wie lange es dauert das Passwort zu cracken und es einmal versucht. Zuerst habe ich es in anderen Programmiersprachen geschrieben, aber das war mir zu langsam. Also bin ich im Moment daran ein Programm für meine GPU (Grafikkarte) zu schreiben, damit es schneller läuft.", "", "/lib/gpu.jpg"),
      new Project("Conductive-Paint Taschenrechner", "Selbst gebauter Taschenrechner mit stromleitender Farbe und LED-Display", "Schon als ich das erste Mal mit Conductive-Paint zu tun hatte, hat sie mich fasziniert. Deswegen wollte ich etwas Grösseres mit der Farbe bauen. Dann sind mein Vater und ich auf die Idee mit dem Taschenrechner gekommen. Wir benutzten als Hauptcontroller einen Arduino UNO und für die Inputs, die über Conductive-Paint gehen 2 Bare-Conductive Boards. Für die Anzeige benutzten wir ein LED-Display, für das ich erst mal ein Programm schreiben musste, um es anzusteuern. Wir arbeiteten mindestens ein paar Monate daran, bis es jetzt endlich funktioniert.", "", "/lib/calculater.jpg"),
      new Project("Primzahlen-Berechner", "Ein Programm das möglichst viele Primzahlen, möglichst schnell berechnet.", "Dieses Projekt ist eigentlich durch das GPU Projekt entstanden. Ich habe nämlich herausgefunden, wie ich Python code auf der GPU laufen lassen kann. Also beschloss ich, als kleine Übung ein Programm zu schreiben, das Primzahlen berechnet. Schlussendlich habe ich dann ziemlich viel Zeit reingesteckt, und es wurde schneller und auch grösser. ", "", "/lib/PrimeCalculater.png"),
      new Project("Eigene 3D Physics-Engine", "Meine eigene 3D Physics-Engine über den ich die 3D-Mathematik gelernt habe.", "3D Grafiken haben mich schon immer fasziniert. Ich wollte wissen, wie diese 3D Objekte auf einem 2D Monitor angezeigt werden. Also beschloss ich eine Physics-Engine zu programmieren. Inzwischen gibt es zwar immer noch Unklarheiten, jedoch habe ich ziemlich viel gelernt und verstehe jetzt die Grundlagen.", "", "/lib/PhysicsEngine.png", true)
    ]
  }),

  components: {
    CubeBackground,
    gradient
  }
}
</script>

<style scoped>

</style>