<template>
  <div style="width: 100vw; height: 100vh">
    <div style="position: absolute; top: 40vh; left: calc(50vw - 100px); text-align: center">
      <v-card style="background-color: #8282b7; text-align: center; height: 50px; width: 200px">
        <a
            href="/lib/Lebenslauf.pdf"
            style="color: white; font-size: xx-large"
        >
          Download
        </a>
      </v-card>
    </div>

    <p style="color: #8282b7; position: absolute; top: calc(40vh + 55px); left: calc(50vw - 133px)">
      ⤛Online version is comming soon.⤜
    </p>
  </div>
</template>

<script>

export default {
  name: "Resume",
}
</script>

<style scoped>
</style>