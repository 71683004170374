<template>
  <div class="about page">
    <b-card-group columns style="margin: 30px">
      <b-card
          img-src="https://images.unsplash.com/photo-1614518917999-966b27c5bd08?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
          overlay
          style="overflow: auto"
          img-height="100%"
      >
        <img
            style="background-image: linear-gradient(0deg, #ffffffaa, #ffffffaa); width: 100.2%; height: 100.5%; position: absolute; left: -1px; top: -1px">
        <div style="position: absolute; left: 0; margin: 0 10px  10px">
          <b-card-title>
            Über mich
          </b-card-title>
          <v-divider/>
          <b-card-text>
            Ich bin nicht nur am Computer sehr begabt, sondern auch im Handwerklichen. Ich experimentiere gerne und habe
            schon mit dem Arduino und Breadboards gearbeitet. Ich kann gut programmieren und interessiere mich für
            allerlei Zeug, das mit Elektrizität zu tun hat. Ich habe schon gelötet, meine eigenen Schaltkreise entworfen
            und die Platinen bestellt. Ich spiele gerne bei den Flying Angels Ultimate Frisbee und mit meinen
            Freunden am Computer Videogames.
          </b-card-text>
        </div>
      </b-card>


      <b-card
          img-src="https://images.unsplash.com/photo-1604549944235-3e5579b15cc2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80"
          overlay
          style="overflow: auto"
          img-height="100%"
      >
        <img
            style="background-image: linear-gradient(0deg, #ffffffaa, #ffffffaa); width: 100.2%; height: 100.5%; position: absolute; left: -1px; top: -1px">
        <div style="position: absolute; left: 0; margin: 0 10px  10px;">
          <b-card-title>
            Herkunft
          </b-card-title>
          <v-divider/>
          <b-card-text>
            Ich wurde 2007 in Bern geboren. Meine Muttersprache ist Hochdeutsch und ich spreche Schweizerdeutsch. Mein
            Vater arbeitete bei Swisscom aber inzwischen bei Adesso. Meine Mutter ist Medizinische Masseurin in ihrer
            eigenen Praxis. Ich habe zwei Brüder. Einer ist 7 Jahre älter und der andere 10 Jahre älter als ich. Ich kam
            mit 5 Jahren in den Kindergarten und übersprang dann das zweite Kindergartenjahr. Ich war immer der Jüngste
            in der Klasse. Es ging mir immer sehr gut. Meine Brüder lernten Informatik und als ich 8 Jahre alt war,
            wollte ich es auch lernen. Ich startete zuerst mit Scratch und später dann mit Java.
          </b-card-text>
        </div>
      </b-card>


      <b-card
          img-src="https://images.unsplash.com/photo-1601662528567-526cd06f6582?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80"
          overlay
          style="overflow: auto"
          img-height="400vw"
      >
        <img
            style="background-image: linear-gradient(0deg, #ffffff00, #ffffff00); width: 100.2%; height: 100.1%; position: absolute; left: -1px; top: -1px">
        <div style="position: absolute; left: 0; margin: 0 10px 10px;">
          <b-card-title>
            Verhalten
          </b-card-title>
          <v-divider/>
          <b-card-text>
            Ich bin freundlich und helfe gerne Mitschülern, wenn sie etwas nicht verstehen, kann gut erklären und habe
            Geduld. Als Teamplayer spiele ich fair, habe keine Probleme mit verlieren und gebe nicht so schnell auf.
            Wenn ich beim programmieren auf ein Problem stosse und es nach 2 Stunden nicht gelöst habe, dann kann es
            schon mal sein das ich eine Pause mache. Aber ein paar Tage später, wenn ich eine neue Idee habe, komme ich
            zurück und mache weiter.
          </b-card-text>
        </div>
      </b-card>


      <b-card title="Erfahrung" sub-title="Meine Erfahrungen mit Programmieren und Anderem." style="min-width: 300px">
        <v-list>
          <v-list-item v-for="experience in experiences">
            <h6>
              {{ experience.direction }}
            </h6>
            <v-spacer/>
            <b-progress style="width: 50%; margin-top: 10px; margin-right: 10px" :value="experience.experience"
                        :max="100" class="mb-3"/>
            <h6>
              {{ experience.experience }}
            </h6>
          </v-list-item>
        </v-list>
      </b-card>


      <b-card title="Sprachkompetenzen" sub-title="Sprachen die ich lerne/spreche." style="min-width: 300px">
        <v-list>
          <v-list-item v-for="language in languages">
            <h6>
              {{ language.language }}
            </h6>
            <v-spacer/>
            <b-progress style="width: 50%; margin-top: 10px; margin-right: 10px" :value="language.experience" :max="100"
                        class="mb-3"/>
            <h6>
              {{ language.experience }}
            </h6>
          </v-list-item>
        </v-list>
      </b-card>


      <b-card
          img-src="https://images.unsplash.com/photo-1542435503-956c469947f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80"
          overlay
          style="overflow: hidden"
          img-height="100%"
      >
        <img
            style="background-image: linear-gradient(0deg, #ffffffaa, #ffffffaa); width: 1000%; height: 1000%; position: absolute; left: -1px; top: -1px">
        <div style="position: absolute">
          <b-card-title>
            Lebenslauf
          </b-card-title>
          <v-divider/>
          <b-card-text>
            Tabellarischer Lebenslauf zum download.
          </b-card-text>
        </div>
        <a href="/lib/Lebenslauf.pdf" style="position: absolute; bottom: 5px">>Lebenslauf<</a>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import CubeBackground from '../components/Cube-Background'

export default {
  name: 'About',

  data: () => ({
    languages: [
      {"language": "Deutsch", "experience": 100},
      {"language": "Japanisch", "experience": 10},
      {"language": "Englisch", "experience": 80},
      {"language": "Französisch", "experience": 25},
    ],
    experiences: [
      {"direction": "Kotlin", "experience": 90},
      {"direction": "Java", "experience": 85},
      {"direction": "C#", "experience": 60},
      {"direction": "Python", "experience": 80},
      {"direction": "CSS", "experience": 65},
      {"direction": "Html", "experience": 85},
      {"direction": "JavaScript", "experience": 60},
      {"direction": "C", "experience": 12},
      {"direction": "Rust", "experience": 10},
      {"direction": "Unity", "experience": 60},
      {"direction": "KiCad", "experience": 55},
      {"direction": "Microsoft Programme", "experience": 70},
    ]
  }),


  components: {
    CubeBackground
  }
}
</script>