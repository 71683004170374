<template>
<h1>
  Das ist ein Test Projekt
</h1>
</template>

<script>
export default {
  name: "TestProject"
}
</script>

<style scoped>

</style>