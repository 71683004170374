<template>
  <div style="width: 100%; text-align: center; margin-top: 100px">
    <h1 style="font-size: 20vh; color: #8282b7">
      404
    </h1>
    <br>
    <h3 style="color: #8282b7; font-size: 3vh; margin: 10px">
      Woops! It seems this page does not exist.  ¯\_(ツ)_/¯
    </h3>
    <br>
    <router-link to="home" style="font-size: 2vh">
      Home Page
    </router-link>
  </div>
</template>

<script>
export default {
  name: "RoutNotFound"
}
</script>

<style scoped>

</style>