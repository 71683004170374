import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify, {
    options: {
        customProperties: true
    }
})

const col3 = "#c0c0c0";
const col5 = "#eaeaea";
const col6 = "#8282b7";
const col7 = "#2b2b2c";
const col8 = "#232325";
const col9 = "#6670b9";
const error = "#E40017";
const col10 = "#d472d7";
const col11 = "#ba63de";

export default new Vuetify({
    theme: {
        dark: false,

        themes: {
            light: {
                primary: col6,
                secondary: col5,
                tertiary: col6,
                accent: col3,
                error: error,
                grad: col10,
                navbar: col6
            },

            dark: {
                primary: col9,
                secondary: col7,
                tertiary: col9,
                accent: col3,
                error: error,
                grad: col11,
                navbar: col8
            }
        },
    },
});
