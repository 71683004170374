<template>
  <v-app>
    <v-sheet
        width="100%"
        min-height="100%"
    >
      <v-app-bar
          fixed
          color="navbar"
          elevate-on-scroll
          height="75px"
      >
        <v-app-bar-nav-icon @click="drawer = true" style="color: white"></v-app-bar-nav-icon>

        <button @click="goHome" style="color: white; font-size: xx-large; background-color: #00000000; margin-left: 20px">
          Oliver Seifert
        </button>
      </v-app-bar>

      <v-navigation-drawer
          v-model="drawer"
          fixed
          temporary
      >
        <v-list
            nav
            dense
        >
          <v-list-item-group
              v-model="group"
              active-class="primary--text"
          >
            <v-list-item inactive/>
            <v-list-item inactive>
              <h2>
                Oliver Seifert
              </h2>
            </v-list-item>
            <v-list-item color="#00000000" id="home" @click="updateTab('home')">
              Home
            </v-list-item>
            <v-list-item id="about" @click="updateTab('about')">
              About
            </v-list-item>
            <v-list-item id="projects" @click="updateTab('projects')">
              Projects
            </v-list-item>
          </v-list-item-group>

          <v-list-item/>

          <v-list-item v-b-popover.hover.top="'Not done yet'">
            <h6 v-if="!$vuetify.theme.dark">
              Dark mode 🌙 ➞
            </h6>
            <h6 v-if="$vuetify.theme.dark">
              Light mode 🌞 ➞
            </h6>
            <v-spacer/>
            <v-switch :color="$vuetify.theme.currentTheme.accent" v-model="$vuetify.theme.dark" disabled/>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <gradient/>
      <cube-background/>

      <v-main style="width: 100%; position: absolute; top: 75px; overflow: hidden">
        <router-view/>
      </v-main>
    </v-sheet>
  </v-app>
</template>

<script>

import router from "./router";
import cubeBackground from "./components/Cube-Background"
import gradient from "./components/gradient";

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
  }),

  methods: {
    updateTab(side) {
      router.push("/" + side)
    },
    page() {
      return router.currentRoute.name
    },
    goHome() {
      router.push('/home')
    }
  },

  components: {
    cubeBackground,
    gradient
  }
};
</script>

<style>
::-webkit-scrollbar {
  width: 0;
}
</style>