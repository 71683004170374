import Vue from 'vue'
import VueRouter from 'vue-router'
import Projects from "../views/Projects";
import About from "../views/About";
import Home from "../views/Home";
import RoutNotFound from "../views/RoutNotFound";
import Resume from "../views/Resume";
import TestProject from "../views/project-sides/TestProject";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: {name: "Home"}
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects
    },
    {
        path: '/resume',
        name: 'Resume',
        component: Resume
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: RoutNotFound
    },
    {
        path: '/projects/test-project',
        name: 'test-project',
        component: TestProject
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
